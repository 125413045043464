import React from 'react';
import PropTypes from 'prop-types';
import Box from '@material-ui/core/Box';
import { makeStyles } from '@material-ui/core/styles';
import clsx from 'clsx';

const useStyles = makeStyles(theme => ({
  loader: {
    backgroundColor: ({ backgroundColor }) => backgroundColor
  }
}));

const Loader = ({ backgroundColor, className, message, messageColor, info }) => {
  const classes = useStyles({ backgroundColor });

  return (
    <Box
      className={clsx(classes.loader, className, 'loader')}
      position="absolute"
      top={0}
      left={0}
      height="100%"
      width="100%"
      zIndex={1111222222}
      display="flex"
      alignItems="center"
      justifyContent="center"
    >
      {message && <Box color={messageColor}>{message}</Box>}
      {info && (
        <Box color={messageColor} className="info">
          {info}
        </Box>
      )}
    </Box>
  );
};

Loader.propTypes = {
  backgroundColor: PropTypes.string,
  className: PropTypes.string,
  message: PropTypes.oneOfType([PropTypes.string, PropTypes.node]),
  info: PropTypes.string,
  messageColor: PropTypes.string
};

Loader.defaultProps = {
  backgroundColor: '#ffffffb3',
  className: null,
  message: null,
  info: null,
  messageColor: null
};

export default Loader;
